import { graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect } from "react"
import Image from "../components/image"
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
const Isotope = typeof window !== `undefined` ? require("isotope-layout") : null

const ProjectPage = ({ data: { wpProject: post } }) => {
  let {
    title,
    content,
    seo,
    featuredImage,
    taxServices,
    acf_gallery: { gallery },
  } = post

  useEffect(() => {
    // console.log("did mount")
    var iso = undefined
    if (iso === undefined) {
      iso = new Isotope(".grid-items", {
        layoutMode: "masonry",
        masonry: {
          columnWidth: ".grid-sizer",
          gutter: 0,
        },
        itemSelector: ".post",
        percentPosition: true,
      })
      iso.arrange({ filter: `.post` })
    }
  })

  // console.log(taxServices.nodes[0].name)

  return (
    <Layout location="/architecture/" title={title}>
      <SEO bodyClasses="architecture" seo={seo} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1 className="heading">{parse(taxServices?.nodes[0]?.name)}</h1>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <Image data={featuredImage} classes="featured-image" />
          </div>
          <div className="col-md-5 pl-5">
            {!!title && <h2 className="">{parse(title)}</h2>}
            {parse(content)}
          </div>
        </div>

        {!!gallery && (
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="heading">Project Gallery</h1>
            </div>
          </div>
        )}

        <div className="row no-gutters grid-items grid gallery">
          <div className="grid-sizer"></div>
          {!!gallery &&
            gallery.map(post => {
              post.node = post.image
              return (
                <div
                  className={`post ${
                    post.layout !== "none" ? post.layout : ""
                  } `}
                  key={post.image.id}
                >
                  <Image data={post} />
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpProject(id: { eq: "cG9zdDoxMTk=" }) {
      taxServices {
        nodes {
          name
          slug
        }
      }
      title
      content
      acf_project_fields {
        location
        client
        size
        year
        cost
        category
        scope
      }
      featuredImage {
        node {
          ...fragmentImage
        }
      }
      acf_gallery {
        gallery {
          image {
            ...fragmentImage
          }
          layout
        }
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`

export default ProjectPage
